var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { classMap } from "lit/directives/class-map.js";
import radioStyles from "./radio.styles";
import { FormControl } from "../shared/components/form-control/form-control.component";
/**
 * Radios allow users to choose one option from a limited set of options. They have to be used inside a Fieldset component.
 * @slot - Custom content to show inside the radio. You will have to provide the label, sublabel and error yourself.
 * @slot nested - The content to show when the radio is checked.
 * @slot label-start - The content to show before the label. Useful for an icon, for example.
 * @slot label-end - The content to show after the label. Useful for a tooltip toggle, for example.
 */
let Radio = class Radio extends FormControl {
  constructor() {
    super(...arguments);
    /** Controls whether the radio is checked or not. */
    this.checked = false;
    /** Show the nested content, regardless of the checked state. */
    this.showNested = false;
    this.hasSlottedNestedElements = false;
    this.formControlType = "radio";
  }
  updated(changedProperties) {
    if (changedProperties.get("checked") === false && this.checked) {
      this.control.focus();
    }
  }
  render() {
    var _a;
    const radioHTML = html`<div
      class=${classMap({
      "bi-radio": true,
      "bi-radio--hasLabel": !!this.label
    })}
    >
      <input
        id=${this.randomId}
        class="bi-form-control bi-radio-control"
        type="radio"
        name=${ifDefined(this.controlName)}
        .value=${(_a = this.value) !== null && _a !== void 0 ? _a : ""}
        .checked=${this.checked}
        ?disabled=${this.disabled}
        ?required=${this.required}
        @input=${this.handleInput}
        @change=${this.handleChange}
      />
    </div>`;
    const nestedHTML = html`<div
      class=${classMap({
      "bi-radio-nested": true,
      "bi-radio-nested--hasNestedElements": this.hasSlottedNestedElements
    })}
    >
      <slot name="nested" @slotchange=${this.handleNestedSlotChange}></slot>
    </div>`;
    return html`${radioHTML} ${this.getContentHTML()} ${nestedHTML}`;
  }
  /** Focuses the radio button. */
  focus() {
    this.control.focus();
  }
  /** Checks the radio. */
  check() {
    this.checked = true;
    this.setValue(this.value);
  }
  /** Unchecks the radio. */
  uncheck() {
    this.checked = false;
    this.setValue(this.value);
  }
  /** Toggles the radio. */
  toggle() {
    this.checked = !this.checked;
    this.setValue(this.value);
  }
  handleInput(event) {
    this.checked = this.control.checked;
    super.handleInput(event);
  }
  getContentHTML() {
    return this.label || this.error ? html`<div class="bi-radio-content">
          ${this.getLabelHTML(this.hasSlottedNestedElements)}
          ${this.getSublabelHTML()} ${this.getErrorHTML()}
        </div>` : html`<label class="bi-radio-custom" for=${this.randomId}>
          <slot></slot>
        </label>`;
  }
  handleNestedSlotChange() {
    this.hasSlottedNestedElements = this.slottedNestedElements.length > 0;
  }
};
Radio.styles = radioStyles;
__decorate([property({
  type: Boolean,
  reflect: true
})], Radio.prototype, "checked", void 0);
__decorate([property({
  type: Boolean,
  attribute: "show-nested"
})], Radio.prototype, "showNested", void 0);
__decorate([queryAssignedElements({
  slot: "nested",
  selector: "*"
})], Radio.prototype, "slottedNestedElements", void 0);
__decorate([state()], Radio.prototype, "hasSlottedNestedElements", void 0);
Radio = __decorate([customElement("bi-radio")], Radio);
export { Radio };
